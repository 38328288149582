import { ErrorHandler, Injectable, Provider, inject } from '@angular/core';
import { LoggerService } from './logger.service';

@Injectable()
class GlobalErrorHandler implements ErrorHandler {

  private logger = inject(LoggerService);

  public handleError(error: unknown): void {
    this.logger.renderer.error('Global error handler', {
      error,
    });
  }

}

export function provideGlobalErrorHandler(): Provider {
  return {
    provide: ErrorHandler,
    useClass: GlobalErrorHandler,
  }
}
