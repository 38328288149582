import { UrlTree, DefaultUrlSerializer } from '@angular/router';
import { Injectable } from '@angular/core';

@Injectable()
export class CustomUrlSerializer extends DefaultUrlSerializer {

  public override serialize(tree: UrlTree): string {
    const url = super.serialize(tree);
    return CustomUrlSerializer.normalize(url);
  }

  public static normalize(url: string): string {
    const splitCharacter = url.indexOf('?') !== -1 ? '?' : '#';

    const urlParts = url.split(splitCharacter);

    // the firstspirit content creator checks if the url is the same before and after changes, in these urls there is a session token with s=XXX if this is encoded this check fails
    urlParts[0] = urlParts[0].replaceAll('%3D', '=');

    // The default url serializer removes trailing slashes, but we need them
    if (!urlParts[0].endsWith('/') && !urlParts[0].includes('.')) {
      urlParts[0] += '/';
    }
    return urlParts.join(splitCharacter);
  }

}
