import { Injectable } from '@angular/core';
import dialogPolyfill from 'dialog-polyfill';
import { DialogPolyfillService } from './dialog-polyfill.service';

@Injectable({
  providedIn: 'root',
})
export class DialogPolyfillBrowserService implements DialogPolyfillService {

  public registerDialog(element: HTMLDialogElement): void {
    dialogPolyfill.registerDialog(element);
  }

}
