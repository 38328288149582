import { mergeApplicationConfig, ApplicationConfig } from '@angular/core';
import { appConfig } from './app.config';
import { provideHtmlParserService, HtmlParserBrowserService, provideDeviceTypeService, DeviceTypeBrowserService } from '@knorr-bremse-portals/ngx-components';
import { DialogPolyfillBrowserService } from './providers/dialog-polyfill.browser.service';
import { provideDialogPolyfillService } from './providers/dialog-polyfill.service';
import { PERFORMANCE, REFERRER } from '../express.tokens';

const config: ApplicationConfig = {
  providers: [
    provideDialogPolyfillService(DialogPolyfillBrowserService),
    provideHtmlParserService(HtmlParserBrowserService),
    provideDeviceTypeService(DeviceTypeBrowserService),
    { provide: PERFORMANCE, useValue: window.performance },
    { provide: REFERRER, useValue: document.referrer },
  ],
};

export const browserConfig = mergeApplicationConfig(appConfig, config);
