import { Injectable, inject } from '@angular/core';
import { BaseLocationService } from './base-location.service';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class WebmanifestService {

  private baseLocation = inject(BaseLocationService);
  private document = inject(DOCUMENT);

  public init(): void {
    const webmanifest: HTMLLinkElement = this.document.createElement('link');
    webmanifest.rel = 'manifest';
    webmanifest.href = `${this.baseLocation.origin}/manifest.webmanifest`;
    this.document.head.appendChild(webmanifest);
  }

}
