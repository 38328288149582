import { Injectable, InjectionToken, inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { UserConsentService } from './user-consent.service';
import { filter, take } from 'rxjs/operators';
import { PerformanceConsentCategory } from './one-trust.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

export interface GoogleTagManagerServiceConfigurationInterface {
  id?: string;
  auth?: string;
  environment?: string;
}

export const googleTagManagerServiceConfiguration = new InjectionToken<GoogleTagManagerServiceConfigurationInterface>('googleTagManagerServiceConfiguration');

const googleTagManagerDataLayerName = 'googleTagManagerDataLayer';

declare const window: {
  [googleTagManagerDataLayerName]: { [key: string]: string | number }[];
}

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerService {

    private document = inject(DOCUMENT);
    private configuration = inject(googleTagManagerServiceConfiguration);
    private userConsentService = inject(UserConsentService);

    public consent = this.userConsentService.forCategory(PerformanceConsentCategory);

    public init() {
      if (!this.configuration.id) { // A missing id disables the google tag manager
        return;
      }

      this.consent.given$.pipe(
        filter((given) => given === true),
        take(1),
        takeUntilDestroyed(),
      ).subscribe((given) => {
        if (!given) {
          return;
        }

        (window)[googleTagManagerDataLayerName] = [];
        (window)[googleTagManagerDataLayerName].push({
          'gtm.start': new Date().getTime(),
          event: 'gtm.js',
        });
        const script = this.document.createElement('script');
        script.src = `https://www.googletagmanager.com/gtm.js?id=${this.configuration.id}&l=${googleTagManagerDataLayerName}`;
        if (this.configuration.auth) {
          script.src += `&gtm_auth=${this.configuration.auth}`;
        }
        if (this.configuration.environment) {
          script.src += `&gtm_preview=${this.configuration.environment}`;
        }
        script.async = true;
        this.document.body.appendChild(script);
      });
    }

}
